/* -Campaign member block
---------------------------------------------------------------------------- */
.campaign-member-block {
  @include section-margin;
}
.campaign-member-content {
  margin-bottom: rem(30px);
  max-width: $wrap-width-thin;
}

.campaign-item {
  margin-bottom: rem(30px);
  padding: 25px;
  border-radius: 18px;
  background-color: $color-green-pale;

  ul {
    @include checklist;
  }
  .membership-price {
    display: block;
    color: $color-green;
    font-weight: $font-weight-bold;
    font-size: rem(30px);
  }
}

.campaign-info-item {
  padding: 25px;
  border-radius: 18px;
  background-color: $color-green-pale;

  ol {
    margin-top: 20px;
    counter-reset: item;
    list-style: none;
    margin-left: 50px;

    li {
      position: relative;
      counter-increment: item;
      margin-bottom: rem(15px);
      min-height: 35px;
      display: flex;
      align-items: center;

      &::before {
        left: -50px;
        position: absolute;
        margin-right: 15px;
        content: counter(item);
        width: 35px;
        height: 35px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $color-green-bright;
        color: white;
        font-weight: $font-weight-bold;
      }
      &:last-child {
        &::before {
          content: svg-url(
            '<svg width="40" height="40" viewBox="0 0 15 15"><circle fill="#10aa6e" cx="7.5" cy="7.5" r="7.5"/><path d="M10.2 4.4A1 1 0 0 1 12 5.5l-.1.1L7 11.4 3.4 8.3a1 1 0 0 1 1.1-1.6h.1L7 8.6l3.3-4.2Z" fill="#fff"/></svg>'
          );
          padding-top: rem(6px);
          width: 45px;
          height: 45px;
          left: -55px;
          border: 2px solid #fff;
          outline: 3px dotted $color-green-bright;
          background-color: transparent;
        }
      }
    }
  }
}

@include media-min(rem(850px)) {
  .campaign-items {
    display: flex;
    align-items: flex-start;
  }
  .campaign-item {
    margin-right: rem(40px);
    margin-bottom: 0;
    min-width: rem(320px);
  }
  .campaign-info-item {
    flex-basis: 70%;
  }
}

@include media-min(rem(1000px)) {
  .campaign-item {
    margin-right: rem(60px);
  }
}
